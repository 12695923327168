import React, { Component } from "react";
// import { TimelineLite, CSSPlugin } from "gsap/all";

const navs = [
  { id: "#s1", title: "Highlights" },
  { id: "#s3", title: "Services" },
  { id: "#s4", title: "Portfolio" },
  { id: "#contact", title: "Contact" }
];

class HeaderCSSAnim extends Component {
  constructor() {
    super();
    this.state = {
      width: 0,
      height: 0,
      mobile: false,
      hamburgerExpand: false
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
    if (this.state.width > 590) {
      this.setState({ hamburgerExpand: false, mobile: false });
    }
  }

  handleMenuClick() {
    this.setState({
      mobile: true,
      hamburgerExpand: !this.state.hamburgerExpand
    });
  }

  handleClick() {
    if (this.state.width < 590) {
      this.setState({
        hamburgerExpand: !this.state.hamburgerExpand
      });
    }
  }

  render() {
    const navHTML = navs.map(nav => (
      <a
        key={nav.id}
        href={nav.id}
        onClick={this.handleClick}
        className="nav-link"
      >
        {nav.title}
      </a>
    ));
    const hidden = (
      <div id="nav-text" className="text-right toohigh transform">
        {navHTML}
      </div>
    );
    const visible = (
      <div
        id="nav-text"
        className="text-right visible transform transform-active"
      >
        {navHTML}
      </div>
    );
    const mostHidden = ( //Not animated (used when going from Desktop to Mobile - before implementing this, the slide-out animation would play)
      <div id="nav-text" className="text-right toohigh">
        {navHTML}
      </div>
    );
    return (
      <div id="header">
        <nav id="nav-bar" className="navbar navbar-fixed-top">
          <img
            src="images/A+dev_logo1.png"
            alt="A+ dev logo"
            id="header-img"
          />
          {this.state.width < 590 && (
            <span className="menubtn" onClick={this.handleMenuClick}>
              <i name="menu" className="fas fa-bars fa-2x" />
            </span>
          )}
          {this.state.width > 590 /* Desktop */
            ? visible
            : this.state.mobile ===
              false /* If mobile size, but menu not yet clicked (prevents animation from happening when going from Desktop to Mobile) */
            ? mostHidden /* Unanimated */
            : this.state.hamburgerExpand ===
              false /* If mobile size and menu btn has been clicked (both below animated) */
            ? hidden
            : visible}
        </nav>
      </div>
    );
  }
}

export default HeaderCSSAnim;

import React from "react";

const about = [
  {
    id: 1,
    title: "Experience",
    fa: "fas fa-file-code",
    copy:
    <p>I've honed my skills as a web developer at leading Australian ad agencies, and two Korean startups(as an executive, and on the technical sales/education side). I mostly use HTML5, CSS3, <strong>SASS</strong>, JavaScript ES6, <strong>React.js</strong>, <strong>Node.js</strong>, Bootstrap, Flexbox, CSS Grid, Python, and GreenSock Animation Platform. I'm a proficient user of Adobe CC software, especially Photoshop, Lightroom, Illustrator, InDesign and AfterEffects. I also have experience with Salesforce, WordPress, Magento, and WooCommerce.</p>
  },
  {
    id: 2,
    title: "Professional",
    fa: "fas fa-globe",
    copy:
    <p>
      Benefit from proven results and a wealth of experience in a wide variety of fields on 3 continents. I've worked as a developer, designer, teacher, journalist, entrepreneur, in large and small teams of varying diversity.
    </p>
  },
  {
    id: 3,
    title: "Meticulous",
    fa: "fas fa-check",
    copy:
    <p>
      As a science major at one of Canada's top universities, I learned to think about the big picture and focus on all the details. It's a work ethic I've proudly brought to every project since.
    </p>
  },
  {
    id: 4,
    title: "Efficient",
    fa: "fas fa-angle-double-right",
    copy:
    <p>
      There's no point in reinventing the wheel, especially when time and money are on the line. I'm a firm believer in using the best tools for the job, whether they be custom-made or off the shelf.
    </p>
  },
  {
    id: 5,
    title: "Passionate",
    fa: "fas fa-lightbulb",
    copy:
    <p>
      I love coding, problem-solving, and problem-solving with code. It's who I am. I code recreationally to solve my own problems, and for amusement. I was first exposed to coding at a FreeCodeCamp(FCC) meetup and once I became a developer, I wanted to pay it forward and started the <a href="https://drecali.link/fccu" target="_blank">FCC Ulsan</a> group in South Korea. When I moved to Seoul, I re-activated the dormant <a href="https://drecali.link/fccs" target="_blank">FCC Seoul</a> group. I love talking code, being around coders, mentoring others, and helping people start their own exciting coding journey.
    </p>
  }
];

function S1About() {
  return (
    <div id="s1">
      {about.map(item => (
        <div key={item.id} id={`f${item.id}`}>
          <div className="icon">
            <i className={item.fa} />
          </div>
          <div className="feat-copy">
            <div className="icon_mobile">
              <i className={item.fa} />
            </div>
            <div className="feat-head">{item.title}</div>
            <div className="feat-text">
                {item.copy}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default S1About;

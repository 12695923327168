import React from "react";

const services = [
  {
    id: 1,
    title: "Web Consulting",
    lines: [
      "Development",
      "Design",
      "UX/UI",
      "Contact for more info",
    ],
  },
  {
    id: 2,
    title: "Website Audit",
    lines: [
      "Detailed review",
      "Pinpoint site issues",
      "Receive feedback",
      "Actionable insights",
    ],
  },
  {
    id: 3,
    title: "Static Website",
    lines: [
      "1-5 Pages",
      "Minimalistic design",
      "Custom Domain",
      "Hosting available",
    ],
  },
];

function S3Services() {
  return (
    <div id='s3'>
      <div className='section-head' id='services'>
        <h1>Services</h1>
      </div>
      <div id='offers-all'>
        {services.map((service) => (
          <div
            key={service.id}
            id={`offer${service.id}`}
            className='offers offer-container'
          >
            <div className='offer-head'>{service.title}</div>
            <div className='offer-price'>{service.price}</div>
            <div className='offer-text'>
              {service.lines.map((line) => (
                <div key={service.lines.indexOf(line)} className='offer-line'>
                  {line}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default S3Services;

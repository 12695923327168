import React from "react";

const certs = [
  {
    id: 1,
    type: "certification",
    tags: "fcc, front-end, html, css, js, libraries, api",
    title: "Front End Development",
    img: "images/01a-front-end-cert.png",
    url: "https://www.freecodecamp.org/drecali/front-end-certification"
  },
  {
    id: 2,
    type: "certification",
    tags: "fcc, front-end, html, css, js",
    title: "Responsive Web Design",
    img: "images/01b-responsive-cert.png",
    url:
      "https://www.freecodecamp.org/certification/drecali/responsive-web-design"
  },
  {
    id: 3,
    type: "certification",
    tags: "fcc, js, algorithms",
    title: "JavaScript Algorithms",
    img: "images/01c-javascript-cert.png",
    url:
      "https://www.freecodecamp.org/certification/drecali/javascript-algorithms-and-data-structures"
  },
];

function S5Certifications() {
  return (
    <div id="s5">
      <div className="section-head" id="certifications">
        <h1>Certifications</h1>
      </div>

      <div id="projects-all">
        {certs.map(item => (
          <div key={item.id} className="projects">
            <div className="project-container">
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                <div className="project-head">{item.title}</div>
                <img className="proj-img" src={item.img} alt={item.title} />
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default S5Certifications;

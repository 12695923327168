import React from "react";

const contactArr = [
  {
    id: 1,
    fa: "fab fa-linkedin fa-lg",
    link: "https://www.linkedin.com/in/drecali"
  },
  { id: 2,
    fa: "fab fa-twitter fa-lg",
    link: "https://twitter.com/Dre_Cali"
  },
  { id: 3,
    fa: "fab fa-github fa-lg",
    link: "https://github.com/drecali"
  },
  {
    id: 4,
    fa: "fab fa-free-code-camp fa-lg",
    link: "https://www.freecodecamp.org/drecali"
  }
];

function Contact() {
  return (
    <div id="contact">
      <div id="contact-head">
        <h2>Contact</h2>
        <div id="social-all">
          {contactArr.map(sns => (
            <a
              key={sns.id}
              className="social"
              href={sns.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className={sns.fa} />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Contact;

import React from "react";

const portfolio = [
  {
    id: 1,
    type: "project-solo",
    tags: "html, css, js, bootstrap, korean",
    title: "Korean Number Converter",
    img: "images/krnumber.jpg",
    url: "https://codepen.io/drecali/full/wvWOjKB"
  },
  {
    id: 2,
    type: "project-solo",
    tags: "html, css, js, bootstrap, korean",
    title: "🤣 Korean Name Generator",
    img: "images/knamefun.jpg",
    url: "https://codepen.io/drecali/full/KKwvXVa"
  },
  {
    id: 3,
    type: "project-fcc",
    tags: "fcc, api, html, css, js, bootstrap",
    title: "Local Weather App",
    img: "images/02-fcc-weather.png",
    url: "https://codepen.io/drecali/full/gxPJgM/"
  },
  {
    id: 4,
    type: "project-fcc",
    tags: "fcc, html, css, js, bootstrap",
    title: "JavaScript Calculator",
    img: "images/03-fcc-calc.png",
    url: "https://codepen.io/drecali/full/BdRoXK/"
  },
  {
    id: 5,
    type: "project-fcc",
    tags: "fcc, html, css, js",
    title: "Tic Tac Toe",
    img: "images/04-fcc-xo.png",
    url: "https://codepen.io/drecali/full/QMqKzG"
  },
  {
    id: 6,
    type: "project-fcc",
    tags: "fcc, html, css, js",
    title: "Simon Says",
    img: "images/05-fcc-simon.png",
    url: "https://codepen.io/drecali/full/RZjqrR"
  },
  {
    id: 7,
    type: "project-fcc",
    tags: "fcc, api, html, css, js",
    title: "Random Quote Generator",
    img: "images/06-fcc-quote.png",
    url: "https://codepen.io/drecali/full/RgZzaN/"
  },
  {
    id: 8,
    type: "project-fcc",
    tags: "fcc, api, html, css, js",
    title: "Wikipedia API Viewer",
    img: "images/07-fcc-wiki.png",
    url: "https://codepen.io/drecali/full/dzMQWO/"
  },
  {
    id: 9,
    type: "project-fcc",
    tags: "fcc, api, html, css, js",
    title: "TwitchTV API Viewer",
    img: "images/08-fcc-twitch.png",
    url: "https://codepen.io/drecali/full/dzNrBb/"
  },
  {
    id: 10,
    type: "project-fcc",
    tags: "fcc, html, css, js",
    title: "Pomodoro Clock",
    img: "images/09-fcc-timer.png",
    url: "https://codepen.io/drecali/full/yoozPx"
  }
];

function S4Portfolio() {
  return (
    <div id="s4">
      <div className="section-head" id="portfolio">
        <h1>Portfolio</h1>
      </div>

      <div id="projects-all">
        {portfolio.map(item => (
          <div key={item.id} className="projects">
            <div className="project-container">
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                <div className="project-head">{item.title}</div>
                <img className="proj-img" src={item.img} alt={item.title} />
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default S4Portfolio;

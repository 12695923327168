import React from "react";

function Me() {
  return (
    <div id="me">
      <div id="profile">
        <img
          id="pro-img"
          src="images/Andrei-Google2.jpg"
          alt="Andrei posing with Android statue"
        />
      </div>
      <div id="name_code">&lt;ANDREI CALINESCU&gt;</div>
      <div id="tagline">
        <p>&lt;Front end dev at your service&gt;</p>
      </div>
    </div>
  );
}

export default Me;

import React, { Component } from "react";
import "./AppCSSAnim.css";
import HeaderCSSAnim from "./components/HeaderCSSAnim";
import Me from "./components/Me";
import S1About from "./components/S1About";
import S3Services from "./components/S3Services";
import S4Portfolio from "./components/S4Portfolio";
import S5Certifications from "./components/S5Certifications";
import Contact from "./components/Contact";

class App extends Component {
  render() {
    return (
      <div className="App" id="container">
        <HeaderCSSAnim />
        <div id="content">
          <Me />
          <S1About />
          <S3Services />
          <S4Portfolio />
          <S5Certifications />
          <Contact />
        </div>
      </div>
    );
  }
}

export default App;
